@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body{
  width: 100%;
  height: 100%;
  background-color: rgb(216, 236, 238);
  
}


@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}


.animate-scroll {
  animation: scroll 110s linear infinite;
 
  
}


.dropdow-container:hover .dropdown {
  display: block;
  

}

.dropdown {
  display: none;

}